import React from "react";

//Bosse Wibergh

import "../assets/css/bw.css";
// import "bootstrap/dist/css/bootstrap.min.css";

function BW() {
	return (
    <div className='bw-background'>
      <div className='bw-container'>
        <h2 className='seperator'>Verktøy</h2>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.google.com'>
            <img
              alt='img'
              className='img'
              src='https://www.google.com/images/branding/googlelogo/2x/googlelogo_color_92x30dp.png'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.drive.google.com'>
            <img
              alt='img'
              className='img-small'
              src='https://venturebeat.com/wp-content/uploads/2015/11/google_drive_logo.png?fit=400%2C210&strip=all'
            />
          </a>
        </div>

        <div className='box dark-bg shadow-generic'>
          <a href='https://www.icloud.com'>
            <img
              alt='img'
              className='img-small'
              src='https://upload.wikimedia.org/wikipedia/commons/thumb/1/1c/ICloud_logo.svg/800px-ICloud_logo.svg.png'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.lastpass.com'>
            <img
              alt='img'
              src='https://lastpass.com/media/pressroom/LastPass-Logo-Color.png'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://rf.duett.no/Duett/Core/FrontEnd/WebApplications/Dashboard/'>
            <img
              alt='img'
              src='https://duett.no/wp-content/uploads/2017/09/Logo214x90.png'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://danskebank.no/privat/faa-hjelp?n-login=pbnettbank#'>
            <img
              alt='img'
              src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/9f/Danske_Bank_logo.svg/1200px-Danske_Bank_logo.svg.png'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://gulesider.no'>
            <img
              alt='img'
              src='https://static.eniro.com/img/profiles/no/gulesider_logo_gray.svg'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.altibox.no/minesidero'>
            <img
              alt='img'
              src='https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Altibox_logo.svg/568px-Altibox_logo.svg.png'
            />
          </a>
        </div>

        <h2 className='seperator'>Sosialt</h2>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.facebook.com'>
            <img
              alt='img'
              className='img-small'
              src='https://www.edigitalagency.com.au/wp-content/uploads/Facebook-logo-blue-circle-large-transparent-png.png'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.messenger.com'>
            <img
              alt='img'
              className='img-small'
              src='https://facebookbrand.com/wp-content/uploads/2020/10/Logo_Messenger_NewBlurple-399x399-1.png?w=399&h=399'
            />
          </a>
        </div>

        <div className='box dark-bg shadow-generic'>
          <a href='https://play.spotify.com/'>
            <img
              alt='img'
              className='img-small'
              src='https://www.freepnglogos.com/uploads/spotify-logo-png/file-spotify-logo-png-4.png'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.sverigesradio.se'>
            <img
              alt='img'
              src='https://1.bp.blogspot.com/-skOjK8WGB_o/TYVH8yLNvyI/AAAAAAAAArw/d6PZfhaUKLs/w1200-h630-p-k-no-nu/Sveriges+Radio+logo+2011.png'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.svt.se/'>
            <img
              alt='img'
              src='https://www.svtstatic.se/resources/svtservice-n-render/svt-nyheter-logo_3.svg'
            />
          </a>
        </div>

        <h2 className='seperator'>TV / Streaming</h2>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.svtplay.se/'>
            <img
              alt='img'
              className='img-small'
              src='https://seeklogo.com/images/S/svt-play-logo-3B8CC447B0-seeklogo.com.png'
            />
          </a>
        </div>

        <div className='box dark-bg shadow-generic'>
          <a href='https://www.viafree.se/'>
            <img
              alt='img'
              src='https://static-assets.viafree.tv/4.0.0-0ec8c00d4-149/assets/css/images/viafreelogo_neg.png'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://www.dplay.se/'>
            <img
              alt='img'
              src='https://upload.wikimedia.org/wikipedia/commons/thumb/0/0d/Dplay_logo_2019.svg/1280px-Dplay_logo_2019.svg.png'
            />
          </a>
        </div>

        <div className='box dark-bg shadow-generic'>
          <a href='https://www.viaplay.se/'>
            <img
              alt='img'
              src='https://assets.viaplay.tv/frontend-2020121703/images/header-logo-large.png'
            />
          </a>
        </div>

        <h2 className='seperator'>Bank</h2>
        <div className='box white-bg shadow-generic'>
          <a href='https://nettbank.sor.no/'>
            <img
              alt='img'
              src='https://nettbank.sor.no/hb-doc/cssnb/loginService/rob2811/images/header-logo.png'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://minnettbank.santanderconsumer.no/'>
            <img
              alt='img'
              src='https://www.santanderconsumer.no/Resources/Images/santander-logo.svg'
            />
          </a>
        </div>

        <div className='box dark-bg shadow-generic'>
          <a href='https://identity.banknorwegian.no/MyPage/Login?returnUrl=%2Fconnect%2Fauthorize%2Fcallback%3Fclient_id%3Dmypageno%26redirect_uri%3Dhttps%253A%252F%252Fwww.banknorwegian.no%252Fsignin-oidc-no%26response_type%3Dcode%2520id_token%26scope%3Dopenid%2520profile%2520offline_access%2520bn_token%26state%3DOpenIdConnect.AuthenticationProperties%253D2K9Tlh-49Pqo8AYuawx9JlKg0jmSmz7NG2c3m8Xk75xkCQot7xmvTxuGQhdpGxsvLpSEiSxDD2xDPAmi-ICMfAL9D49-qEPMIHai_KjLhtnDzRt35btqPGGyvbAvHBQd_7b6_5YCgC3VbZzBm1cy5ItlnoTqCn5D0LXTq9-yGlOzRYyMMuocZ0HKye0QRMiOfQ6Vew%26response_mode%3Dform_post%26nonce%3D637439792853831803.MzRlZmQwZTAtZWYwYy00MGIzLWE2OTAtMGE0YjQ2Mjk5ZGNkNjRjOGNhOTktYjU5Yy00NzE1LTg0MDctYTE3YWNiZGMxNjIw%26acr_values%3Didp%253Abn%26dm%3D0%26sid%3De790d35f-86d0-43dc-9934-7f53196cc4d7%26region%3Dnb-NO'>
            <img
              alt='img'
              src='https://identity.banknorwegian.no/content/images/menu/logo.svg'
            />
          </a>
        </div>

        <div className='box white-bg shadow-generic'>
          <a href='https://secure.handelsbanken.se/logon/se/priv/sv/'>
            <img
              alt='img'
              src='https://www.handelsbanken.se/sv/sepu/img/jedi/base/kit/shb-large-logo.svg?rev=18486'
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default BW;
