import React, { useState, useEffect } from "react";
import _ from "lodash";
import { TransitionGroup } from "react-transition-group";
import "bootstrap/dist/css/bootstrap.min.css";
import moment from "moment"
import "./dnd.css"

import { db } from "../../services/firebase";

function DND() {
  const [DNDTitle, setDNDTitle] = useState("");
  const [DNDPerson, setDNDPerson] = useState("");
  const [DNDFrom, setDNDFrom] = useState("");

  const [DNDs, setDNDs] = useState("");

  const rootRef = "DND/";

  useEffect(() => {
    db.ref(rootRef).on("value", (snapshot) => {
      if (snapshot.val()) {
        const sampleArray = _.toArray(snapshot.val());
        setDNDs(sampleArray);
      }
    });
  }, [rootRef]);

  const resetState = () => {
    setDNDFrom("");
    setDNDTitle("");
  };

  // TODO: Lag mulighet for hemmelighe notater fra DM
  // TODO: Lage private notater på hver spiller 


  const writeDND = () => {
    // A post entry.
    let postData = {
      title: DNDTitle,
      person: DNDPerson,
      from: DNDFrom ? DNDFrom : "DM",
      date: Date(),
    };

    // Get a key for a new Post.
    let newQuestionKey = db.ref().child("DND").push().key;

    let updates = {};
    updates[rootRef + newQuestionKey] = postData;
    // Get a key htmlFor a new Post.
    resetState();
    return db.ref().update(updates);
  };
  return (
    <div className='container mainContent dnd'>
      <div className='row'>
        <div className='col-12'>
          <h1>DND</h1>
          <TransitionGroup
            transitionName='example'
            transitionEnterTimeout={300}
            transitionLeaveTimeout={300}
          >
            {DNDs ? (
              <div className='row'>
                <h4>Storyline - Army Of The Dammned</h4>
                <p>Dette er DM notes, ikke nødvendigvis mye hemmelig men her står det ting jeg jobber for hver session</p>
                {DNDs.map((DND, i) => (
                  <div key={i} className='dnd-entry col-12'>
                    <h2>{DND.title}</h2>
                    <h3 classname="text-muted">PC - {DND.person}</h3>
                    <h4 className='text-muted'>{moment(DND.date).format('MM/D/YY h:mm:ss')}</h4>
                  </div>
                ))}
              </div>
            ) : (
              <div>Ingenting å vise her</div>
            )}
          </TransitionGroup>
        </div>
      </div>
      <br></br>
      <div className='row'>
        <div className='col-12'>
          <input
            className='form-control'
            type='text'
            placeholder='Event'
            required
            value={DNDTitle}
            onChange={(e) => setDNDTitle(e.target.value)}
          />
          <input
            className='form-control text-muted'
            type='text'
            placeholder='PC'
            value={DNDPerson}
            onChange={(e) => setDNDPerson(e.target.value)}
          />

          <button
            onClick={writeDND}
            className='btn btn-primary'
            type='button'
          >
            Send inn forslag
          </button>
        </div>
      </div>
    </div>
  );
}

export default DND;
